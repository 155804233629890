import EntityService from "./entity";

class AdvertisementService extends EntityService {
  constructor(name) {
    super(name)
  }
}

export default new AdvertisementService("advertisement")


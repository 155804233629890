import request from "../libs/request";
import EntityService from "./entity";

class AdvertisementCategoryService extends EntityService {
  constructor(name) {
    super(name)
  }
  async getEntityPage (category, pagination) {
    const result = await request({
      url: `/advertisement-categories`,
      method: "get",
      params: {
        ...category,
        ...pagination,
      },
    });
    return result;
  }
}

export default new AdvertisementCategoryService("advertisement-category")
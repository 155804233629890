<template>
  <div class="notification">
    <div class="search-wrapper">
      <a-form-model
        :model="filters"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="description" label="描述">
          <a-input
            v-model="filters.description"
            type="text"
            placeholder="输入描述搜索"
            allowClear
            @change="
              () => {
                if (filters.description == '') delete filters.description;
              }
            "
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="广告位" prop="name">
          <y-select
            v-model="filters.category"
            placeholder="请选择广告位"
            :whole="false"
            :dataSource="advertisementCategories"
          ></y-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
            <a-button
              icon="redo"
              :loading="loading"
              htmlType="reset"
              @click="handleReset"
            >
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <advertisement-table
      :loading.sync="loading"
      :entity="entity"
      :filters="filters"
      :sorter="[
        { field: 'createTime', order: 'DESC' },
        { field: 'sequence', order: 'DESC' },
      ]"
    ></advertisement-table>
  </div>
</template>

<script>
import AdvertisementCategoryService from "@/services/advertisement-category";

import AdvertisementTable from "./table";

const defaultEntity = {};
export default {
  components: { AdvertisementTable },
  data() {
    return {
      loading: false,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      filters: {},
      advertisementCategories: [],
    };
  },
  methods: {
    handleSearch() {
      this.filters = JSON.parse(JSON.stringify(this.filters));
    },
    handleReset() {
      this.filters = {};
    },
    async getAdvertisementCategories() {
      const result = await AdvertisementCategoryService.getEntities();
      this.advertisementCategories = result;
    },
  },
  async mounted() {
    this.handleReset();

    await this.getAdvertisementCategories(this.advertisement);
  },
};
</script>

<style>
</style>
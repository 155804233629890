<template>
  <a-drawer
    :visible="visible"
    width="50%"
    :maskClosable="false"
    @close="handleClose"
  >
    <a-form-model :model="entity" @submit.prevent="handleSave">
      <!-- <a-form-model-item
        label="名称"
        prop="name"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.name" allowClear></a-input>
      </a-form-model-item> -->
      <a-form-model-item
        label="描述"
        prop="description"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.description" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="通告链接"
        prop="link"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.link" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="广告位"
        prop="category"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-select
          v-model="entity.category"
          placeholder="请选择广告位"
          :whole="false"
          :dataSource="advertisementCategories"
          @change="handleAdvertisementCategoryChange"
        ></y-select>
      </a-form-model-item>
      <!-- <a-form-model-item
        label="投放页面地址"
        prop="path"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.path" allowClear :disabled="true"></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="类型"
        prop="type"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-radio
          v-model="entity.type"
          :type="'AdvertisementType'"
          :disabled="true"
        ></y-radio>
      </a-form-model-item> -->

      <!-- <a-form-model-item
        v-if="entity.type == 'EXHIBITOR'"
        label="展商ID"
        prop="exhibitor.id"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.exhibitor.id" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        v-if="entity.type == 'EXHIBIT'"
        label="展品ID"
        prop="exhibit.id"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.exhibit.id" allowClear></a-input>
      </a-form-model-item> -->
      <a-form-model-item
        label="图片"
        prop="picture"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <y-upload
          v-model="entity.picture"
          :maximumQuantity="1"
          :minimumQuantity="1"
          type="picture"
          accept="image/*"
        ></y-upload>
      </a-form-model-item>
      <a-form-model-item
        v-if="entity.type == 'PICTURE'"
        label="跳转链接"
        prop="link"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input v-model="entity.link" allowClear></a-input>
      </a-form-model-item>
      <a-form-model-item
        label="排序"
        prop="sequence"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-input-number
          v-model="entity.sequence"
          :defaultValue="1"
          :min="0"
        ></a-input-number>
      </a-form-model-item>
      <a-form-model-item
        label="状态"
        prop="enabled"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-switch v-model="entity.enabled" />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
        <a-space>
          <a-button htmlType="submit" :loading="loading" type="primary">
            保存
          </a-button>
          <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import Enums from "@/enums/index.js";
import AdvertisementService from "@/services/advertisement";
import AdvertisementCategoryService from "@/services/advertisement-category";

const defaultEntity = {
  properties: {},
  document: {},
};

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Object,
    },
  },
  data() {
    return {
      enums: Enums,
      service: AdvertisementService,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      advertisementCategories: [],
    };
  },
  watch: {
    target: {
      handler: function name(value) {
        this.entity = {
          ...defaultEntity,
          ...value,
        };

        if (!this.entity.properties) {
          this.entity.properties = {};
        }

        if (!this.entity.document) {
          this.entity.document = {};
        }
      },
      deep: true,
    },
  },
  methods: {
    handleClose() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));

      this.$emit("close");

      this.$emit("update:visible", false);
      this.$emit("update:loading", false);
    },
    handleAdvertisementCategoryChange(category) {
      if (category && category.id) {
        const c = this.advertisementCategories.find((c) => c.id == category.id);
        this.entity.path = c.path;
        this.entity.type = c.type;
      } else {
        this.entity.path = null;
        this.entity.type = null;
      }
    },
    async handleSave() {
      try {
        this.$emit("update:loading", true);
        if (!this.entity.id) {
          //this.entity.category = { id: "1" };
          await this.service.addEntity(this.entity);
        } else {
          await this.service.updateEntity(this.entity);
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));

        this.$emit("close");
        this.$emit("update:visible", false);
        this.$emit("update:loading", false);
      }
    },
    async getAdvertisementCategories() {
      const result = await AdvertisementCategoryService.getEntities();
      this.advertisementCategories = result;
    },
  },
  async mounted() {
    await this.getAdvertisementCategories(this.advertisement);
  },
};
</script>

<style>
</style>
